<template>
  <div>
    <div class="tech-req__title">
      {{text.menu_tolerance[language]}}
    </div>
    <div class="tech-req__card tech-req__card_plus" v-if="showEdit">
      <div>
        <div class="block block__center">
          <Plus />
        </div>
        <div class="block block__center">
          <div class="tech-req__card_plus_text">
            {{ text.sz_surface_add[language] }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Plus from '@/assets/pictures/tech/plus.svg';
import text from '@/assets/text/techV2.json';

export default {
  name: 'AccuracyType',
  data() {
    return {
      text,
      showEdit: true,
      activeClick: false,
    };
  },
  props: {
    edit: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    edit: function (val) {
      this.showEdit = val;
    },
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
  },
  methods: {
    surfaceClicked(name) {
      if (this.activeClick) {
        console.log(name);
      }
    },
  },
  mounted() {
    this.showEdit = this.edit;
    this.$store.commit('modelV2/setSurfaceClick', this.surfaceClicked);
  },
  components: {
    Plus,
  },
};
</script>
